
import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import CSpinner from "@/components/spinner.vue";
import { ConsultationCartForm } from "@/store/cart/types";

const VConsultationAdd = defineComponent({
  name: "VConsultationAdd",
  components: { CSpinner },
  setup() {
    useHead({ title: "Agendar consulta | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const loading = reactive({ submit: false });

    const form = reactive<ConsultationCartForm>({
      cd_especialidade: route.query.sy?.toString() || null,
      cd_especialista: route.query.st?.toString() || null,
      cd_estabelecimento: route.query.et?.toString() || null,
      cd_pessoa_fisica: route.query.pf?.toString() || null,
    });

    function showErrorMessage() {
      store.commit("addToast", {
        summary: "OPS... Tivemos problemas ao agendar, tente novamente mais tarde!",
        severity: "error",
      });
      router.back();
    }

    function validate() {
      return (
        form.cd_especialidade &&
        form.cd_especialista &&
        form.cd_estabelecimento &&
        form.cd_pessoa_fisica &&
        Number(route.query.hr)
      );
    }

    async function addConsultationToCart() {
      loading.submit = true;
      const response = await store.dispatch("addConsultationToCart", { _id: Number(route.query.hr), form });
      loading.submit = false;

      if (!response) return router.back();
      if (response.status !== 200) return showErrorMessage();

      store.commit("setToast", [{ summary: "Agendamento adicionado ao carrinho!", severity: "success" }]);
      router.replace({ name: "cart" });
    }

    if (validate()) addConsultationToCart();
    else {
      showErrorMessage();
      router.replace({ name: "consultation", query: route.query });
    }

    return { loading };
  },
});

export default VConsultationAdd;
